@mixin placeholder {
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content; } } }

// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content; } }

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content; } }

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content; } }

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content; } }

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content; } }

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content; } }

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content; } }

@mixin desktop-only {
  @if $widescreen-enabled {
    @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
      @content; } } }

@mixin until-widescreen {
  @if $widescreen-enabled {
    @media screen and (max-width: $widescreen - 1px) {
      @content; } } }

@mixin widescreen {
  @if $widescreen-enabled {
    @media screen and (min-width: $widescreen) {
      @content; } } }

@mixin widescreen-only {
  @if $widescreen-enabled and $fullhd-enabled {
    @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
      @content; } } }

@mixin until-fullhd {
  @if $fullhd-enabled {
    @media screen and (max-width: $fullhd - 1px) {
      @content; } } }

@mixin fullhd {
  @if $fullhd-enabled {
    @media screen and (min-width: $fullhd) {
      @content; } } }

// Placeholders

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

%unselectable {
  @include unselectable; }

@mixin arrow($color: transparent) {
  border: 3px solid $color;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em; }

%arrow {
  @include arrow; }

@mixin bottom-overlay($offset: 0) {
  bottom: $offset;
  right: 0;
  left: 0;
  position: absolute; }

%overlay {
  bottom: 5px;
  right: 5px;
  position: absolute; }

@mixin button($background-color: $twitch-purple,$background-color-hover: $twitch-purple-hover, $text-color: $white) {
  display: inline-block;
  padding: 8px 16px;
  background-color: $background-color;
  border: 0;
  color: $text-color;
  border-radius: 4px;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  transition: 500ms linear background-color;
  svg {
    fill: currentColor;
    height: 1.2em;
    width: 1.2em;
    vertical-align: -4px; }
  &:hover,&:focus {
    background-color: $background-color-hover;
    color: $text-color; } }

@mixin transparent-button-small {
  display: inline-flex;
  align-items: center;
  padding: 1px 4px;
  background-color: $body-background-color;
  border: 1px solid $twitch-purple;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  color: $twitch-purple;
  transition: 500ms linear background-color;
  svg {
    fill: currentColor;
    height: 1.2em;
    width: 1.2em;
    flex: none;
    margin-right: 2px; }
  &:hover,&:focus {
    background-color: $transparent-button-hover; } }

@mixin button-with-shadow {
  @include button;
  text-shadow: 1px 1px 0 rgba(0,0,0,0.8);
  padding: 14px 20px;
  font-weight: bold;
  svg {
    filter: drop-shadow(1px 1px 0 rgba(0,0,0,0.8)); } }

@mixin container {
  margin: 0 auto;
  position: relative;
  @include desktop {
    max-width: $desktop - (2 * $gap);
    width: $desktop - (2 * $gap);
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none;
      width: auto; } }
  @include until-widescreen {
    &.is-widescreen {
      max-width: $widescreen - (2 * $gap);
      width: auto; } }
  @include until-fullhd {
    &.is-fullhd {
      max-width: $fullhd - (2 * $gap);
      width: auto; } }
  @include widescreen {
    max-width: $widescreen - (2 * $gap);
    width: $widescreen - (2 * $gap); }
  @include fullhd {
    max-width: $fullhd - (2 * $gap);
    width: $fullhd - (2 * $gap); } }
