// Colors
$twitch-purple: #6441A5;
$twitch-purple-hover: #7550BA;
$stream-background-color: #101014;
$featured-background-color: #FFF9D7;
$transparent-button-hover: #f1eef1;
$button-blue: hsl(204, 86%, 53%);
$twitch-panel-border: #dad8de;

$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;
$grey-lightest: hsl(0, 0%, 92%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white: hsl(0, 0%, 100%) !default;

$link: $twitch-purple;
$link-hover: $twitch-purple-hover;

// Default properties

$body-background-color: #fafafa;
$body-size: 16px;
$body-rendering: optimizeLegibility;
$body-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$body-color: #4a4a4a;
$body-weight: 400;
$body-line-height: 1.5;

$code-family: monospace;
$code-padding: 0.25em 0.5em 0.25em;
$code-weight: normal;
$code-size: 0.875em;
$code-background: $body-background-color;
$code: $grey-darker;

$hr-background-color: $body-background-color;
$hr-height: 2px;
$hr-margin: 1.5rem 0;

$strong-color: $grey-darker;
$strong-weight: 700;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$l_mobile: 426px !default;
$mobile_nav: 520px !default;
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;
